import React, { useMemo } from 'react';
import { graphql } from 'gatsby';

import SEO from '@components/seo';

import Layout from '@components/Layout/Layout';

import Container from '@components/Container';
import Grid, { Row } from '@components/Grid';
import TextHero from '@components/TextHero';
import Card, { CardImage } from '@components/Card';
import UspList from '@components/UspList/UspList';
import useSearchParam from '@src/hooks/useSearchParam.hook';
import {
  ContentfulCard,
  ContentfulDialogContent,
  ContentfulQuestion,
  ContentfulTextList,
  ContentfulCardsSectionComponent,
} from '../../graphql-types';
import useDialogRoute from '@src/components/Dialog/useDialogRoute.hook';
import ContentfulDialog from '@src/components/Dialog/ContentfulDialog';
import useContentfulImage from '@src/hooks/useContentfulImage.hook';
import Illustration from '@src/components/Illustration/Illustration';
import SmsDownloadForm from '@src/components/SmsDownloadForm/SmsDownloadForm';
import CardPackageInfo from '@src/components/Home/CardPackageInfo';

import CardsImage from '../icons/2.0/creditcards/CreditCards.svg';

const Lan = ({ data, location: { search } }) => {
  const adtractionId = useSearchParam('at_gd');
  if (adtractionId) {
    localStorage.setItem('at_gd', adtractionId);
  }

  const sgclid = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('sgclid');
  }, [search]);

  const {
    headLine,
    pageHeader,
    pageHeaderDescription: { pageHeaderDescription },
    questions,
    questionsHeading,
    pageContent,
  } = data.contentfulPage;

  const usps = pageContent[0] as ContentfulTextList;

  const downloadAppCard = pageContent[1] as ContentfulCard;
  const downloadAppImg = useContentfulImage(downloadAppCard.backgroundImage);

  const reseforsakringCard = pageContent[2] as ContentfulCard;
  const reseforsakringDialogData = pageContent[3] as ContentfulDialogContent;
  const reseforsakringDialog = useDialogRoute('reseforsakring');

  const betalaUtomlandsCard = pageContent[4] as ContentfulCard;
  const openLoanImage = useContentfulImage(betalaUtomlandsCard.backgroundImage);
  const betalaUtomlandsDialogData = pageContent[5] as ContentfulDialogContent;
  const betalaUtomlandsDialog = useDialogRoute('betalautomlands');

  const sparaCard = pageContent[6] as ContentfulCard;
  const sparaCardImage = useContentfulImage(sparaCard.backgroundImage);
  const sparaDialogData = pageContent[7] as ContentfulDialogContent;
  const sparaDialog = useDialogRoute('spara');

  return (
    <Layout
      path="/lan"
      theme="white"
      questions={questions as ContentfulQuestion[]}
      questionsHeading={questionsHeading}
      navbarProps={{
        hideLinks: true,
      }}
    >
      <SEO title={headLine} />
      <Container style={{ paddingTop: 76, paddingBottom: 68 }}>
        <Grid>
          <div style={{ maxWidth: 448, marginBottom: 32 }}>
            <TextHero
              leftAligned
              size="medium"
              title={pageHeader}
              subtitle={pageHeaderDescription}
              sticky
            >
              <UspList items={usps.advancedTextListItems} />
            </TextHero>
          </div>
          <Card centerContent backdropImage={downloadAppImg} noBorder>
            <SmsDownloadForm
              title={downloadAppCard.title}
              formProps={{
                redirectToAppstore: true,
                affiliateCode: 'ae',
                affiliateSclId: sgclid,
              }}
            />
          </Card>
          <Card
            title={reseforsakringCard.title}
            text={reseforsakringCard.subtitle}
            cta={reseforsakringCard.linkText}
            onClick={reseforsakringDialog.openDialog}
            theme="white"
            backdropContent={
              <>
                <Illustration
                  name="Cash1"
                  size={125}
                  style={{ position: 'absolute', left: '68%', top: '37%' }}
                />

                <Illustration
                  name="Cash2"
                  size={145}
                  style={{ position: 'absolute', left: 116, top: '50%' }}
                />

                <Illustration
                  name="Cash3"
                  size={100}
                  style={{ position: 'absolute', left: '64%', top: '76%' }}
                />
              </>
            }
          ></Card>
          <Card
            theme="white"
            backdropImage={openLoanImage}
            title={betalaUtomlandsCard.title}
            text={betalaUtomlandsCard.subtitle}
            cta={betalaUtomlandsCard.linkText}
            onClick={betalaUtomlandsDialog.openDialog}
            backdropContent={
              <CardsImage
                style={{
                  position: 'absolute',
                  bottom: -70,
                  right: '-31%',
                  width: '110%',
                }}
              />
            }
          />
          <Row>
            <Card
              title={sparaCard.title}
              text={sparaCard.subtitle}
              cta={sparaCard.linkText}
              wide
              onClick={sparaDialog.openDialog}
            >
              <CardImage imageData={sparaCardImage} />
            </Card>
          </Row>
        </Grid>
      </Container>
      <CardPackageInfo />
      <ContentfulDialog
        contentfulData={reseforsakringDialogData}
        visible={reseforsakringDialog.isOpen}
        onClose={reseforsakringDialog.closeDialog}
      />
      <ContentfulDialog
        contentfulData={betalaUtomlandsDialogData}
        visible={betalaUtomlandsDialog.isOpen}
        onClose={betalaUtomlandsDialog.closeDialog}
      />
      <ContentfulDialog
        contentfulData={sparaDialogData}
        visible={sparaDialog.isOpen}
        onClose={sparaDialog.closeDialog}
      />
    </Layout>
  );
};

export default Lan;

export const query = graphql`
  query ArlandaExpress {
    contentfulPage(pageUrl: { eq: "/ae" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
        childMarkdownRemark {
          html
        }
      }
      pageContactHeader
      questionsHeading
      questions {
        answer {
          answer
        }
        question
      }
      pageContent {
        ... on ContentfulCard {
          additionalLinkPath
          additionalLinkText
          linkPath
          linkText
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
        }
        ... on ContentfulTextList {
          advancedTextListItems {
            text
            icon
          }
          listName
        }
        ... on ContentfulCardsSectionComponent {
          entryTitle
          card {
            additionalLinkPath
            additionalLinkText
            subtitle
            title
            linkText
            linkPath
          }
        }
        ... on ContentfulDialogContent {
          id
          name
          heading
          headerImage {
            title
            gatsbyImageData(width: 630)
          }
          textContent {
            textContent
          }
          ctaText
          ctaHref
          extraContent {
            ... on ContentfulTextList {
              advancedTextListItems {
                text
                icon
              }
              listName
            }
          }
          questions {
            answer {
              answer
            }
            question
          }
        }
      }
    }
  }
`;
